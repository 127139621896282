import ls from "@/utils/local-storage";
import i18n from "@/locale/i18n";

/**
 * 规则：
 * 1、长度需8-xx位
 * 2、不能包含字符串['XX', 'XX', 'XX', 'XX']
 * 3、不能包含连续3位及以上相同字母或数字
 * 4、不能包含3个及以上字典连续字符
 * 5、不能包含3个及以上键盘连续字符
 * 6、不能包含特殊字符&%=+
 * 7、数字、小写字母、大写字母、特殊字符，至少包含三种
 */

// 长度需8-xx位，数字、小写字母、大写字母、特殊字符，至少包含三种
function validateFormat(val) {
    const passwrodReg = /((^(?=.*[a-z])(?=.*[A-Z])(?=.*\W)[\da-zA-Z\W]{8,160}$)|(^(?=.*\d)(?=.*[A-Z])(?=.*\W)[\da-zA-Z\W]{8,160}$)|(^(?=.*\d)(?=.*[a-z])(?=.*\W)[\da-zA-Z\W]{8,160}$)|(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\da-zA-Z\W]{8,160}$))/;
    return passwrodReg.test(val);
}

// 不能包含字符串
function excludeString(val) {
    let account = ls.get("userInfo") ? ls.get("userInfo").account : '';
    let strs = account ? [account] : [];
    if (account.indexOf('a') != -1) {
        val = val.replace(/@/g, 'a');
    }
    return strs.every(item => {
        return !val.toLowerCase().includes(item);
    });
}

// 键盘字符表(小写)
// 非shift键盘字符表
const charTable1 = [
    ["`", "1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-", "=", ""],
    ["", "q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "[", "]", "\\"],
    ["", "a", "s", "d", "f", "g", "h", "j", "k", "l", ";", "'", "", ""],
    ["", "z", "x", "c", "v", "b", "n", "m", ",", ".", "/", "", "", ""]
];

// shift键盘的字符表
const charTable2 = [
    ["~", "!", "@", "#", "$", "%", "^", "&", "*", "(", ")", "_", "+", ""],
    ["", "q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "{", "}", "|"],
    ["", "a", "s", "d", "f", "g", "h", "j", "k", "l", ":", '"', "", ""],
    ["", "z", "x", "c", "v", "b", "n", "m", "<", ">", "?", "", "", ""]
];

// 所有键盘组合值
let charAll = []

function getCharAll() {
    charAll = []
    // 横向所有的值(正序和反序)-
    charTable1.concat(charTable2).forEach((item, index) => {
        charAll.push(item.join(''), Object.assign([], item).reverse().join(''))
    })

    // 反斜杠纵向所有的值(正序和反序)\
    for (let i = 0; i < charTable1[0].length; i++) {
        let sb1 = []
        let sb2 = []
        charTable1.forEach(item => {
            sb1.push(item[i])
        })
        charTable2.forEach(item => {
            sb2.push(item[i])
        })
        // 去除长度小于3的值
        sb1.join('').length < 3 || charAll.push(sb1.join(''), Object.assign([], sb1).reverse().join(''))
        sb1.join('').length < 3 || charAll.push(sb2.join(''), Object.assign([], sb2).reverse().join(''))
    }

    // 正斜杠纵向所有的值(正序和反序)/
    for (let i = 0; i < charTable1[0].length; i++) {
        let sb1 = []
        let sb2 = []
        charTable1.forEach((item, index) => {
            i - index < 0 || sb1.push(item[i - index])
        })
        charTable2.forEach((item, index) => {
            i - index < 0 || sb2.push(item[i - index])
        })
        // 去除长度小于3的值
        sb1.join('').length < 3 || charAll.push(sb1.join(''), Object.assign([], sb1).reverse().join(''))
        sb1.join('').length < 3 || charAll.push(sb2.join(''), Object.assign([], sb2).reverse().join(''))
    }
    charAll.push('abcdefghijklmnopqrstuvwxyz')
    return charAll
}

// 校验键盘连续性和连续重复
function validateChar(val) {
    getCharAll();
    let password = val.toLowerCase();
    // 密码3位比对
    for (let i = 0; i < password.length - 2; i++) {
        let n1 = password[i];
        let n2 = password[i + 1];
        let n3 = password[i + 2];
        // 判断重复字符
        if (n1 === n2 && n1 === n3) {
            return false;
            // 判断键盘连续字符
        } else if (charAll.some(item => {
            return item.includes(n1 + n2 + n3);
        })) {
            return false
        }
    }
}

// 不能包含&%=+
function excludeSymbol(val) {
    let reg = /[&%=+]/;
    return !reg.test(val);
}

// 校验密码
export default function checkPassword(rule, value, callback) {
    const useStrongPass = ls.get("platformConfig")?.useStrongPass || 0;
    if (useStrongPass == 1) {
        switch (false) {
            case validateFormat(value):
                // 密码不能少于8位且需要包含大写字母、小写字母、数字与特殊符号中的任意三项
                return Promise.reject(i18n.global.t('login.strong_password_rules_tips_1'));
            case excludeString(value):
                // 密码应与用户名无相关性
                return Promise.reject(i18n.global.t('login.strong_password_rules_tips_2'));
            case excludeSymbol(value):
                // 密码不能包含& % = + 这四个符号
                return Promise.reject(i18n.global.t('login.strong_password_rules_tips_3'));
            case validateChar(value):
                // 密码不能包含3个及以上键盘连续字符或连续3位及以上相同字母或数字
                return Promise.reject(i18n.global.t('login.strong_password_rules_tips_4'));
            default:
                return Promise.resolve();
        }
    } else {
        if (value && value.length < 8) {
            // 密码不能少于8位
            return Promise.reject(i18n.global.t('login.password_len'));
        } else {
            return Promise.resolve();
        }
    }
}