<template>
  <a-modal
    :title="$t('CompletePersonnelInformation')"
    :width="680"
    :centered="true"
    :maskClosable="false"
    :bodyStyle="{ padding: '12px 24px' }"
    :visible="visible"
    :confirmLoading="loading"
    class="complete-information-modal"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="information-wrap">
      <h3>{{ $t("NeedToImproveInformation") }}</h3>
      <a-form
        ref="formRef"
        :model="formData"
        :labelCol="{ span: 4 }"
        :wrapperCol="{ span: 20 }"
      >
        <template v-for="item in fieldList" :key="item.key">
          <template v-if="item.defaultField == 1">
            <!-- 手机号 -->
            <a-form-item
              v-if="item.key == 'mobile'"
              :label="item.name"
              :name="item.key"
              :rules="[
                {
                  required: true,
                  message: $t('PleaseEnter') + item.name,
                  trigger: 'change',
                },
                { validator: validatorMobile },
              ]"
            >
              <a-input
                allowClear
                v-model:value="formData[item.key]"
                :maxlength="11"
                :placeholder="$t('PleaseEnter') + item.name"
              />
            </a-form-item>
            <!-- 部门 -->
            <a-form-item
              v-if="item.key == 'department'"
              :label="item.name"
              :name="item.key"
              :rules="[
                {
                  required: true,
                  message: $t('PleaseSelect') + item.name,
                  trigger: 'change',
                  type: 'number',
                },
              ]"
            >
              <a-tree-select
                allowClear
                v-model:value="formData[item.key]"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '30vh', overflow: 'auto' }"
                :tree-data="departmentList"
                :placeholder="$t('PleaseSelect') + item.name"
                treeNodeFilterProp="title"
                :replaceFields="{
                  children: 'list',
                  title: 'name',
                  key: 'id',
                  value: 'id',
                }"
              ></a-tree-select>
            </a-form-item>
          </template>
          <template v-else-if="item.defaultField == 2">
            <!-- 1:文本框 -->
            <a-form-item
              v-if="item.type === 1"
              :label="item.name"
              :name="item.key"
              :rules="[
                {
                  required: true,
                  message: $t('PleaseEnter') + item.name,
                  trigger: 'change',
                },
              ]"
            >
              <a-input
                allowClear
                v-model:value="formData[item.key]"
                :maxlength="50"
                :placeholder="
                  $t('PleaseEnter') +
                  item.name +
                  $t('WithinNWords', { total: 50 })
                "
              />
            </a-form-item>
            <!-- 2:下拉框 -->
            <a-form-item
              v-else-if="item.type == 2"
              :label="item.name"
              :name="item.key"
              :rules="[
                {
                  required: true,
                  message: $t('PleaseSelect') + item.name,
                  trigger: 'change',
                  type: 'object',
                },
              ]"
            >
              <a-select
                allowClear
                v-model:value="formData[item.key]"
                :placeholder="$t('PleaseSelect') + item.name"
                labelInValue
                :options="item.fieldOptions"
              />
            </a-form-item>
            <!-- 4:数值 -->
            <a-form-item
              v-if="item.type === 4"
              :label="item.name"
              :name="item.key"
              :rules="[
                {
                  required: true,
                  message: $t('PleaseEnter') + item.name,
                  trigger: 'change',
                  type: 'number',
                },
              ]"
            >
              <a-input-number
                allowClear
                v-model:value="formData[item.key]"
                :placeholder="$t('PleaseEnter') + item.name"
                :precision="item.len || 0"
                style="width: 100%"
              />
            </a-form-item>
            <!-- 5:日期组件 -->
            <a-form-item
              v-if="item.type === 5"
              :label="item.name"
              :name="item.key"
              :rules="[
                {
                  required: true,
                  message: $t('PleaseSelect') + item.name,
                  trigger: 'change',
                },
              ]"
            >
              <a-date-picker
                :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                valueFormat="YYYY-MM-DD"
                v-model:value="formData[item.key]"
                allowClear
                style="width: 100%"
                :placeholder="$t('PleaseSelect') + item.name"
              />
            </a-form-item>
            <!-- 6:文本域 -->
            <a-form-item
              v-if="item.type === 6"
              :label="item.name"
              :name="item.key"
              :rules="[
                {
                  required: true,
                  message: $t('PleaseEnter') + item.name,
                  trigger: 'change',
                },
              ]"
            >
              <a-textarea
                v-model:value="formData[item.key]"
                :maxlength="500"
                :placeholder="
                  $t('PleaseEnter') +
                  item.name +
                  $t('WithinNWords', { total: 500 })
                "
                showCount
                :rows="4"
                allowClear
              />
            </a-form-item>
            <!-- 7:时间组件 -->
            <a-form-item
              v-if="item.type === 7"
              :label="item.name"
              :name="item.key"
              :rules="[
                {
                  required: true,
                  message: $t('PleaseSelect') + item.name,
                  trigger: 'change',
                },
              ]"
            >
              <a-date-picker
                :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
                valueFormat="YYYY-MM-DD HH:mm"
                :show-time="{ format: 'HH:mm' }"
                format="YYYY-MM-DD HH:mm"
                v-model:value="formData[item.key]"
                allowClear
                style="width: 100%"
                :placeholder="$t('PleaseSelect') + item.name"
              />
            </a-form-item>
            <!-- 8:树 -->
            <a-form-item
              v-if="item.type === 8"
              :label="item.name"
              :name="item.key"
              :rules="[
                {
                  required: true,
                  message: $t('PleaseSelect') + item.name,
                  trigger: 'change',
                  type: item.isCheck == 1 ? 'array' : 'object',
                },
              ]"
            >
              <a-tree-select
                allowClear
                labelInValue
                v-model:value="formData[item.key]"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '30vh', overflow: 'auto' }"
                :tree-data="item.fieldOptions"
                :placeholder="$t('PleaseSelect') + item.name"
                treeNodeFilterProp="title"
                :multiple="item.isCheck == 1"
                :treeCheckable="item.isCheck == 1"
                :showCheckedStrategy="
                  item.isSelectChild == 1 ? 'SHOW_CHILD' : 'SHOW_ALL'
                "
                :replaceFields="{
                  children: 'children',
                  title: 'optionValue',
                  key: 'optionKey',
                  value: 'optionKey',
                }"
                treeDefaultExpandAll
              ></a-tree-select>
            </a-form-item>
          </template>
        </template>
      </a-form>
      <h3 v-if="reject">{{ $t("ReasonForRejection") }}</h3>
      <p v-if="reject">{{ reject }}</p>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, watch, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import ls from "@/utils/local-storage";
import { validatorMobile } from "@/utils/formRules";

import {
  getDepartmentTree,
  userCompleteInformation,
  userCompleteInformationSubmit,
  userCompleteInformationAuditSubmit,
  userCompleteInformationToSave,
} from "@/api/user";
import { auditTmplDetailbyType } from "@/api/auditCenter";
export default {
  name: "completeInformation",
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      visible: false,
      loading: false,
      fieldList: [],
      reject: "",
      departmentList: [],
      formData: {},
    });
    const formRef = ref(null);

    const getDepartment = () => {
      getDepartmentTree().then((res) => {
        state.departmentList = res.data || [];
      });
    };

    const isInformation = () => {
      userCompleteInformation().then((res) => {
        state.fieldList = res.data.editField || [];
        state.reject = res.data.reject || "";
        if (state.fieldList.length) {
          state.visible = true;
          if (res.data.memberJson) {
            state.formData = res.data.memberJson || {};
            if (state.formData.fields) {
              for (let key in state.formData.fields) {
                state.formData[key] = state.formData.fields[key];
              }
            }
          }
          state.fieldList.forEach((item) => {
            if (item.defaultField == 1) {
              if (item.key == "department") {
                getDepartment();
              }
            } else if (item.defaultField == 2) {
              // 表单渲染
              if (item.type == 2) {
                item.fieldOptions.map((opt) => {
                  opt.label = opt.optionValue;
                  opt.value = opt.optionKey;
                });
              }
              if (item.type == 8 && item.isSelectChild == 1) {
                if (item.isCheck == 1) {
                  isLeaf(item.fieldOptions, "selectable");
                } else {
                  isLeaf(item.fieldOptions, "disabled");
                }
              }
              // 赋值
              if (item.type == 2 || item.type == 8) {
                if (state.formData[item.key])
                  state.formData[item.key] = JSON.parse(
                    state.formData[item.key]
                  );
              }
              if (item.type == 4) {
                if (state.formData[item.key])
                  state.formData[item.key] = Number(state.formData[item.key]);
              }
            }
          });
          formRef.value && formRef.value.resetFields();
        }
      });
    };

    watch(
      [() => route.path, () => store.getters.isInformation],
      () => {
        if (
          route.meta.requireAuth &&
          !route.meta.lecturerZone &&
          store.getters.isInformation &&
          route.path != "/audit"
        ) {
          isInformation();
        }
      },
      { immediate: true, deep: true }
    );

    const isLeaf = (arr, type) => {
      arr.forEach((item) => {
        if (item.children?.length) {
          item.selectable = false;
          isLeaf(item.children, type);
        }
      });
    };

    const handleCancel = () => {
      state.visible = false;
    };

    const handleOk = () => {
      let data = JSON.parse(JSON.stringify(state.formData));
      formRef.value.validate().then(() => {
        let data2 = {
          fields: {},
        };
        state.fieldList.forEach((item) => {
          if (data[item.key]) {
            if (item.defaultField == 1) {
              data2[item.key] = data[item.key];
            } else {
              if (item.type == 2 || item.type == 8) {
                data2.fields[item.key] = JSON.stringify(data[item.key]);
              } else if (item.type == 4) {
                data2.fields[item.key] = String(data[item.key]);
              } else {
                data2.fields[item.key] = data[item.key];
              }
            }
          }
        });
        state.loading = true;
        userCompleteInformationSubmit(data2).then((res) => {
          state.loading = false;
          if (res.ret == 0) {
            auditTmplDetailbyType(2).then((res2) => {
              let auditReceiptNode = res2.data.audittmplnode.map((item) => {
                return {
                  appterMembers: item.appterMembers,
                  nodeId: item.srcref,
                };
              });
              let menu = ls.get("companyInfo").menu;
              let userId = ls.get("userInfo").userId;
              let appterMembers =
                res2.data.audittmplnode[0].appterMembers || [];
              if (
                menu.indexOf("audit") == -1 ||
                appterMembers.find((o) => o.id == userId) ||
                res2.data.status != 1
              ) {
                userCompleteInformationToSave(data2).then((res3) => {
                  if (res3.ret == 0) {
                    proxy.$message.success(proxy.$t("CM_Success"));
                    handleCancel();
                  }
                });
              } else {
                userCompleteInformationAuditSubmit({
                  auditId: res2.data.auditId,
                  resourceId: res.data,
                  auditReceiptNode: auditReceiptNode,
                }).then((res3) => {
                  if (res3.ret == 0) {
                    proxy.$message.success(proxy.$t("CM_Success"));
                    handleCancel();
                  }
                });
              }
            });
          }
        });
      });
    };

    return {
      validatorMobile,
      ...toRefs(state),
      formRef,
      handleCancel,
      handleOk,
    };
  },
};
</script>

<style lang="less" scoped>
.information-wrap {
  h3 {
    position: relative;
    margin: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 32px;
    &::before {
      position: absolute;
      top: 9px;
      left: -12px;
      width: 4px;
      height: 14px;
      background-color: #1989fa;
      content: "";
    }
  }
}
</style>
<style lang="less">
.complete-information-modal {
  .ant-btn-primary {
    background: #1890ff;
    border-color: #1890ff;
  }
}
</style>
