import axios from 'axios';
import { refreshToken, getAuthByKey, getLogoutUrlByqd, OIDCGetAuthUrl } from '@/api/user'
import ls from '@/utils/local-storage';
import { STORAGE_TOKEN_KEY } from '@/store/mutation-type';
import { h } from "vue";
import router from '@/router'
import { clearStorage, getQueryVariable, getCurrentPlatform } from "@/utils/tools.js";
import { message, Modal } from 'ant-design-vue';
import { successCallback } from "@/utils/login";
import i18n from "@/locale/i18n";
import xss from "xss";

export const REQUEST_TOKEN_KEY = 'Authorization';

const ua = navigator && navigator.userAgent ? (navigator.userAgent.toLowerCase() || '') : ''
export const isWxH5 = /micromessenger/i.test(ua); // 是否微信
export const isWxWork = /wxwork/i.test(ua); // 是否企业微信
export const isDingding = /dingtalk/i.test(ua); // 是否钉钉
export const isMobile = /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(ua); // 是否手机端

const request = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	// baseURL: 'http://192.168.3.105:18888/api/',
	// baseURL: 'https://api.test.tgtrain.cn/',
	timeout: 60000 * 3,
});

const errorHandler = error => {
	if (error.response) {
		const { data = {}, status, statusText } = error.response;
		message.error(`error:${status},${(data && data.message) || statusText}`);
	}
	return Promise.reject(error);
};

let refreshFlag = true; //刷新token标识

const AcceptLanguage = {
	'zh-CN': 'zh_CN,zh;q=0.9,en;q=0.8',
	'zh-TW': 'zh_TW;q=0.9,zh;q=0.8,en;q=0.7',
	'en-US': 'en_US,zh;q=0.8,en;q=0.9',
};
const requestHandler = config => {
	const lsToken = ls.get(STORAGE_TOKEN_KEY);
	if (lsToken) {
		config.headers[REQUEST_TOKEN_KEY] = 'token ' + lsToken;
		if (refreshFlag) RefreshToken();
	}
	// 多语言
	const lang = i18n.global.locale.value || 'zh-CN'
	config.headers['Accept-Language'] = AcceptLanguage[lang];
	if (config.method === 'post' && config.url.indexOf('/upload') == -1) {
		const data = Object.assign({}, config.data);
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				if (typeof (data[key]) == 'string') {
					data[key] = xss(data[key]);
				} else {
					data[key] = data[key];
				}
			}
		}
		config.data = data;
	}
	return config;
};

request.interceptors.request.use(requestHandler, errorHandler);

let tokenFlag = true;  // 未授权标识
const key = 'error'; // 当前提示的唯一标志

const responseHandler = response => {
	let data = response.data;
	if (data.ret === 2 || data.ret === 5 || data.ret === 8) {
		if (tokenFlag) {
			let pageRoute = location.pathname + location.search;
			if (pageRoute.indexOf('/login') == -1) {
				ToLogin(pageRoute); //token不存在
				if (data.ret === 8) {
					message.error({
						content: h('div', {
							style: {
								display: 'inline-block',
								verticalAlign: 'top',
								textAlign: 'left'
							}
						}, [
							h('p', {
								style: {
									marginBottom: '5px',
								}
							}, data.msg),
							h('p', {
								style: {
									marginBottom: '5px',
								}
							}, 'ip: ' + data.data.ip),
							h('p', {
								style: {
									marginBottom: 0,
								},
							}, '地址: ' + data.data.address),
						]),
						duration: 6,
						key
					});
				} else {
					message.error({ content: data.msg, key });
				}
				tokenFlag = false;
			}
		}
		return false;
	} else if (data.ret === 7) {
		message.error({ content: data.msg, key });
		router.replace({ path: '/' });
	} else if (data.ret === 99) {
		message.error({ content: data.msg, key });
		router.replace({ path: '/licence' });
	} else {
		tokenFlag = true;
		if (data.ret !== 0) message.error({ content: data.msg, key });
		return data;
	}
};

request.interceptors.response.use(responseHandler, errorHandler);

export const currentHost =
	process.env.NODE_ENV === "production" || process.env.NODE_ENV === "low"
		? location.host
		: "gms.test.tgtrain.cn";
let htsyState = {
	redirect: '',
};
export const ToLogin = (fullPath, logout) => {
	fullPath = fullPath || '/';
	let lsDingCorpid = ls.get("dingCorpid") || '';
	clearStorage();
	Modal.destroyAll();
	if (fullPath.indexOf('htsyautoauth') > -1) {
		const keys = decodeURIComponent(getQueryVariable("htsyautoauth")).split('*');
		htsyState.redirect = `/course/detail?id=${keys[1]}`;
		handleAuthByff(keys[0]);
		return false;
	}
	if (process.env.VUE_APP_CLIENT == 'htsy') { // 三院
		router.replace({ path: '/login_error' });
	} else {
		//通过当前域名获取微信的配置信息
		request.post('study/platform/config', { site: currentHost, platform: getCurrentPlatform(), }).then(res => {
			if (res.ret == 0) {
				// 青岛水务单点登录退出 跳转到青岛水务登录页面
				if (res.data.useIAM == 1 && logout) {
					const param = {
						"customerId": res.data.CustomerID,
						"url": location.protocol + '//' + location.host,
						"state": location.pathname.indexOf('/login') == -1 ? location.pathname : '/',
						"mode": 1, // 1=pc 2=mobile
					}
					getLogoutUrlByqd(param).then(res => {
						window.location.href = res;
					})
				}
				// 单点登录退出
				if (res.data.useOIDC == 1 && logout && res.data.customerCode !== 'hi-p') {
					OIDCGetAuthUrl({
						mode: 1,
						customerId: res.data.CustomerID,
						authMode: 0, // 赫比
						state: location.pathname.indexOf('/login') == -1 ? location.pathname + location.search : '/',
					}).then((OIDCRes) => {
						window.location.href = OIDCRes.data;
					});
				}
				if (!res.data || res.data.appId == '') {
					//H5登入
					router.replace({ path: '/login', query: { redirect: encodeURIComponent(fullPath) } });
					return false;
				}
				let appId = res.data.appId;
				let corpId = res.data.corpId;
				let dingCorpid = getQueryVariable("corpid") || lsDingCorpid;
				let platform = res.data.platform;
				if (platform == 25) {
					const ua = navigator && navigator.userAgent ? (navigator.userAgent.toLowerCase() || '') : ''
					const isWxWork = /wxwork/i.test(ua); // 是否企业微信
					//企业微信客户端打开，先走移动端授权逻辑
					if (isWxWork) {
						location.replace(`${location.protocol}//${res.data.mobileDomain}${fullPath}`);
						return false;
					}
					//企业微信自建应用登入
					let callbackUrl = location.protocol + "//" + currentHost + "/login/wxwork?redirect=" + fullPath;
					location.replace("https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=" + corpId + "&agentid=" + res.data.agentId + "&redirect_uri=" + encodeURIComponent(callbackUrl) + "&state=traingo");
				} else if (platform == 26) {
					const ua = navigator && navigator.userAgent ? (navigator.userAgent.toLowerCase() || '') : ''
					const isWxWork = /wxwork/i.test(ua); // 是否企业微信
					//企业微信客户端打开，先走移动端授权逻辑
					if (isWxWork) {
						location.replace(`${location.protocol}//${res.data.mobileDomain}${fullPath}`);
						return false;
					}
					//企业微信应用市场登入
					let callbackUrl = location.protocol + "//" + currentHost + "/login/wxwork?redirect=" + encodeURIComponent(fullPath);
					// location.replace("https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect?appid=" + corpId + "&redirect_uri=" + encodeURIComponent(callbackUrl) + "&&state=traingo&usertype=member");
					let baseAppId = 'wx024e3e833aa0b22d'
					location.replace("https://login.work.weixin.qq.com/wwlogin/sso/login?appid=" + baseAppId + "&redirect_uri=" + encodeURIComponent(callbackUrl) + "&login_type=ServiceApp&state=traingo");
				} else if (platform == 30 || platform == 31) {
					//钉钉登入
					if (isDingding) {
						if (platform == 31) ls.set("dingCorpid", dingCorpid)
						router.replace({ path: '/login/ding', query: { appId: appId, corpId: corpId, platform: platform, dingCorpid: dingCorpid, redirect: encodeURIComponent(fullPath) } })
					} else {
						router.replace({ path: '/login', query: { redirect: encodeURIComponent(fullPath) } });
					};
				} else if (platform == 40) {
					//飞书H5自建登入
					let callbackUrl = location.protocol + "//" + currentHost + "/login/feishu?self=1&redirect=" + encodeURIComponent(fullPath);
					location.replace("https://open.feishu.cn/open-apis/authen/v1/index?redirect_uri=" + encodeURIComponent(callbackUrl) + "&app_id=" + appId);
				} else if (platform == 41) {
					//飞书H5商店登入
					let callbackUrl = location.protocol + "//" + currentHost + "/login/feishu?redirect=" + fullPath;
					location.replace("https://open.feishu.cn/open-apis/authen/v1/index?redirect_uri=" + encodeURIComponent(callbackUrl) + "&app_id=" + appId);
				} else if (platform == 20) {
					// 微信服务号
					const ua = navigator && navigator.userAgent ? (navigator.userAgent.toLowerCase() || '') : ''
					const isWx = /micromessenger/i.test(ua); // 是否微信
					// 微信客户端打开
					if (isWx) {
						location.replace(`${location.protocol}//${res.data.mobileDomain}${fullPath}`);
						// let callbackUrl = location.protocol + "//" + location.host + "/login/wx?redirect=" + encodeURIComponent(fullPath);
						// location.replace("https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appId + "&redirect_uri=" + encodeURIComponent(callbackUrl) + "&response_type=code&scope=snsapi_base&state=123#wechat_redirect");
						return false;
					} else {
						router.replace({ path: '/login', query: { redirect: encodeURIComponent(fullPath) } });
					}
				} else {
					//H5登入
					router.replace({ path: '/login', query: { redirect: encodeURIComponent(fullPath) } });
					return false;
				}
			} else {
				message.error('参数配置错误');
			}
		});
	}
};

function RefreshToken() {
	let tokenExpire = ls.get("TokenExpire");
	let expireTime = tokenExpire ? new Date(JSON.parse(tokenExpire)) : null;
	let curTime = new Date();
	if (expireTime && curTime > expireTime) {
		refreshFlag = false;
		let refreshTokenValue = ls.get('refreshToken');
		refreshToken({ refreshToken: refreshTokenValue }).then(res => {
			if (res.ret == 0) {
				ls.set(STORAGE_TOKEN_KEY, res.data.token);
				let toLoginTime = parseInt(res.data.expiresIn / 60 - 30);
				let expiredate = new Date(curTime.setMinutes(curTime.getMinutes() + Number(toLoginTime)));
				ls.set("TokenExpire", JSON.stringify(Date.parse(expiredate))); //多少分钟后过期！
				ls.set("refreshToken", res.data.refreshToken);
				refreshFlag = true;
			}
		});
	}
}

// 三院 -- 从火狐浏览器 跳转过来
function handleAuthByff(key) {
	getAuthByKey({ key: key }).then((res) => {
		if (res.ret === 0) {
			successCallback(res, htsyState);
		}
	})
}

export default request;
