import moment from 'moment';
import ls from '@/utils/local-storage';
import { STORAGE_TOKEN_KEY } from '@/store/mutation-type';
import axios from 'axios';
import store from '@/store'
import { viewRegex } from "./formRules";
import i18n from "@/locale/i18n";
const ua = navigator && navigator.userAgent ? (navigator.userAgent.toLowerCase() || '') : ''
// 获取剩余时间
export const timeRmaining = (date) => {
    let newTime = new Date().getTime();
    let day = Math.floor(((date * 1000) - newTime) / 86400000);
    let hours = Math.floor(((date * 1000) - newTime) / 3600000);
    let minute = Math.floor(((date * 1000) - newTime) / 60000);
    if (day == 0) {
        if (hours == 0) {
            return minute + i18n.global.t('CM_Minute');
            // 分钟
        } else {
            return hours + i18n.global.t('CM_Hour');
            // 小时
        }
    } else {
        return day + i18n.global.t('Pub_Day');
    }
};

// 时间戳转时间
export const dateFormat = (time, formatStr = 'YYYY-MM-DD HH:mm') => {
    if (!time) return '--';
    let timeStr = time.toString();
    if (timeStr.length == 10) {
        return moment(time * 1000).format(formatStr)
    } else if (timeStr.length == 13) {
        return moment(time).format(formatStr)
    }
};

// 秒转时分秒
export const formatSeconds = (value, hasDom) => {
    if (!value || value == 0) {
        return '--';
    }
    let result = parseInt(value);
    let h =
        Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
    let m =
        Math.floor((result / 60) % 60) < 10
            ? '0' + Math.floor((result / 60) % 60)
            : Math.floor((result / 60) % 60);
    let s = Math.floor(result % 60) < 10 ? '0' + Math.floor(result % 60) : Math.floor(result % 60);

    let res = '';
    if (hasDom) {
        if (h !== '00') res += `${h}<span>${i18n.global.t('exam.format.h')}</span>`; // 时
        if (m !== '00') res += `${m}<span>${i18n.global.t('exam.format.m')}</span>`; // 分
        res += `${s}<span>秒</span>`;
    } else {
        if (h !== '00') res += `${h}${i18n.global.t('exam.format.h')}`; // 时
        if (m !== '00') res += `${m}${i18n.global.t('exam.format.m')}`; // 分
        res += `${s}${i18n.global.t('exam.format.s')}`; // 秒
        return res;
    }
    return res;
};

// 秒转时分秒
export const formatSeconds2 = (value) => {
    let hours = Math.floor(value / (60 * 60));
    let modulo = value % (60 * 60);
    let minutes = Math.floor(modulo / 60);
    let seconds = modulo % 60;
    if (hours > 0) {
        if (hours < 10) {
            hours = '0' + hours;
        }
    }
    if (minutes < 10) {
        minutes = '0' + minutes;
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }
    if (hours > 0) {
        return hours + ':' + minutes + ':' + seconds;
    } else {
        return minutes + ':' + seconds;
    }
};

// 页面切入属性
export const onShow = () => {
    let prefixes = ["webkit", "moz", "ms", "o"];
    if ("visibilityState" in document) return "visibilityState";
    for (let i = 0; i < prefixes.length; i++) {
        if (prefixes[i] + "VisibilityState" in document)
            return prefixes[i] + "VisibilityState";
    }
    return null;
};

// 页面切出属性
export const onHide = () => {
    let prefixes = ["webkit", "moz", "ms", "o"];
    if ("hidden" in document) return "hidden";
    for (let i = 0; i < prefixes.length; i++) {
        if (prefixes[i] + "Hidden" in document) return prefixes[i] + "Hidden";
    }
    return null;
};

const add_0 = num => Number(num) < 10 ? '0' + num : num

// 秒转时分、分秒、秒
export const formatTime = (time, unit = {
    h: i18n.global.t('exam.format.hh'),
    m: i18n.global.t('exam.format.mm'),
    s: i18n.global.t('exam.format.s')
}) => {
    let hours = parseInt(time / 60 / 60)
    let mins = parseInt(time / 60 % 60)
    let secs = parseInt(time % 60)

    if (hours > 0) {
        return add_0(hours) + unit.h + add_0(mins) + unit.m + add_0(secs) + unit.s
    } else {
        if (mins > 0) {
            return add_0(mins) + unit.m + add_0(secs) + unit.s;
        } else {
            return add_0(secs) + unit.s;
        }
    }
};

//秒转时间
export const convertTimes = (totalSeconds) => {
    let days = Math.floor(totalSeconds / (60 * 60 * 24));
    if (days < 1) days = "00";
    let modulo = totalSeconds % (60 * 60 * 24);
    let hours = Math.floor(modulo / (60 * 60));
    modulo = modulo % (60 * 60);
    let minutes = Math.floor(modulo / 60);
    let seconds = parseInt(modulo % 60);
    minutes = parseInt((days * 24 + hours) * 60 + minutes);
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
};

export const uploadUrl = process.env.VUE_APP_API_BASE_URL;

export const getHeader = () => {
    const savedToken = ls.get(STORAGE_TOKEN_KEY); // 如果 token 存在
    // 让每个请求携带自定义 token, 请根据实际情况修改
    let header = {};
    if (savedToken) {
        header['Authorization'] = 'token ' + savedToken;
    }
    return header;
};

/*
 * 获取CDN路径 
 * @url: 接收的路径
 * @modPath: 模块 temp[零时] template[模版]
 */
export const getCdnUrl = (url, modPath) => {
    let path = modPath ? modPath + '/' : '/';
    if (url.indexOf('http') === -1 || url.slice(0, 2) === '//') {
        return process.env.VUE_APP_RES_URL + path + url;
    } else {
        return url;
    }
};

// 修改favicon
export const upFavicon = (url) => {
    let link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = url;
    document.getElementsByTagName("head")[0].appendChild(link);
};

// 小数转百分比
export const toPercent = n => Math.floor(n * 100) + '%';

// 大小格式化
export const getFileSize = (limit) => {
    let size = '';
    if (limit < 0.1 * 1024) {
        //如果小于0.1KB转化成B
        size = limit.toFixed(2) + 'B';
    } else if (limit < 0.1 * 1024 * 1024) {
        //如果小于0.1MB转化成KB
        size = (limit / 1024).toFixed(2) + 'KB';
    } else if (limit < 0.1 * 1024 * 1024 * 1024) {
        //如果小于0.1GB转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + 'MB';
    } else {
        //其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB';
    }
    var sizestr = size + '';
    var len = sizestr.indexOf('.');
    var dec = sizestr.substr(len + 1, 2);
    if (dec == '00') {
        //当小数点后为00时 去掉小数部分
        return sizestr.substring(0, len) + sizestr.substr(len + 3, 2);
    }
    return sizestr;
}

/**
 * 时间戳转为多久之前
 * @param String timestamp 时间戳
 */
export const timeFrom = (dateTime = null) => {
    // 如果为null,则格式化当前时间
    if (!dateTime) dateTime = Number(new Date());
    // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
    if (dateTime.toString().length == 10) dateTime *= 1000;
    let timestamp = + new Date(Number(dateTime));

    let timer = (Number(new Date()) - timestamp) / 1000;
    // 如果小于5分钟,则返回"刚刚",其他以此类推
    let tips = '';
    switch (true) {
        case timer < 300:
            tips = i18n.global.t('exam.format.now');
            // 刚刚
            break;
        case timer >= 300 && timer < 3600:
            tips = i18n.global.t('exam.format.m_ago', [parseInt(timer / 60)]);
            // 分钟前
            break;
        case timer >= 3600 && timer < 86400:
            tips = i18n.global.t('exam.format.h_ago', [parseInt(timer / 3600)]);
            // 小时前
            break;
        case timer >= 86400 && timer < 2592000:
            tips = i18n.global.t('exam.format.d_ago', [parseInt(timer / 86400)]);
            // 天前
            break;
        default:
            if (timer >= 2592000 && timer < 365 * 86400) {
                tips = i18n.global.t('exam.format.month_ago', [parseInt(timer / (86400 * 30))]);
                // 个月前
            } else {
                tips = i18n.global.t('exam.format.y_ago', [parseInt(timer / (86400 * 365))]);
                // 年前
            }
    }
    return tips;
}

export const watermark = (element, config) => {
    // 获取元素的坐标
    function getOffset(el) {
        return {
            x: el.offsetLeft,
            y: el.offsetTop,
        };
    }
    if (!element) return;
    // 默认配置
    var _config = {
        text1: 'text',   //文本1
        text2: '',   // 文本2
        start_x: 0,      // x轴起始位置
        start_y: 50,      // y轴起始位置
        space_x: 50,    // x轴间距
        space_y: 70,     // y轴间距
        width: 510,      // 宽度
        height: 80,      // 长度
        fontSize: 17,    // 字体
        color: '#aaa',   // 字色
        alpha: 0.4,      // 透明度
        rotate: 15,       // 倾斜度
    };
    // 替换默认配置
    if (typeof config === "object") {
        var src = config || {};
        for (var key in src) {
            if (src[key] && _config[key] && src[key] === _config[key]) {
                continue;
            } else if (src[key]) {
                _config[key] = src[key];
            }
        }
    }
    if (document.getElementsByClassName('watermark-item').length > 0)
        return false;
    // 节点的总宽度
    var total_width = element.scrollWidth;
    // 节点的总高度
    var total_height = element.scrollHeight;
    // 创建文本碎片，用于包含所有的插入节点
    var mark = document.createDocumentFragment();
    // 水印节点的起始坐标
    var position = getOffset(element);
    var x = position.x + _config.start_x, y = position.y + _config.start_y;
    // 先循环y轴插入水印
    do {
        // 再循环x轴插入水印
        do {
            // 创建单个水印节点
            var item = document.createElement('div');
            item.className = 'watermark-item';
            // 设置节点的样式
            item.style.position = "absolute";
            item.style.zIndex = 999;
            item.style.left = '' + x + 'px';
            item.style.top = '' + y + 'px';
            item.style.width = '' + _config.width + 'px';
            item.style.height = '' + _config.height + 'px';
            item.style.fontSize = '' + _config.fontSize + 'px';
            item.style.color = _config.color;
            item.style.textAlign = 'center';
            item.style.opacity = _config.alpha;
            item.style.filter = 'alpha(opacity=' + _config.alpha * 100 + ')';
            item.style.webkitTransform = 'rotate(-' + _config.rotate + 'deg)';
            item.style.MozTransform = 'rotate(-' + _config.rotate + 'deg)';
            item.style.msTransform = 'rotate(-' + _config.rotate + 'deg)';
            item.style.OTransform = 'rotate(-' + _config.rotate + 'deg)';
            item.style.transform = 'rotate(-' + _config.rotate + 'deg)';
            item.style.pointerEvents = 'none';    //让水印不遮挡页面的点击事件
            // 创建text1水印节点
            var text1 = document.createElement('div');
            text1.appendChild(document.createTextNode(_config.text1));
            item.appendChild(text1);
            // 创建text2水印节点
            var text2 = document.createElement('div');
            text2.appendChild(document.createTextNode(_config.text2));
            item.appendChild(text2);
            // 添加水印节点到文本碎片
            mark.appendChild(item);
            // x坐标递增
            x = x + _config.width / 2 + _config.space_x;
            // 超出文本右侧坐标停止插入
        } while (total_width + position.x > x + _config.width);
        // 重置x初始坐标
        x = position.x + _config.start_x;
        // y坐标递增
        y = y + _config.height + _config.space_y;
        // 超出文本底部坐标停止插入
    } while (total_height + position.y > y + _config.height);
    // 插入文档碎片
    element.appendChild(mark);
};

// 动态创建script
export const appendJs = (id, text) => {
    if (!text) return
    let jsContent = "";
    let jsEle = document.getElementById(id);
    if (!jsEle) {
        text.replace(/<script.*?>([\s\S]+?)<\/script>/gim, (_, js) => {
            jsContent = js;
        });
        if (jsContent) {
            let js = document.createElement("script");
            js.setAttribute('id', id);
            js.innerHTML = jsContent;
            document.head.append(js);
        }
    }
};

const secretEnum = {
    '50-': '非密-',
    '60-': '内部-',
    '70-': '秘密-',
    '80-': '机密-',
}
// 下载静态资源时使用此方法
export const fileDownload = (url, fileName) => {
    let digit = url.lastIndexOf('/') + 1;
    let secret = url.slice(digit, digit + 3), secretName = '';
    if (secretEnum[secret]) secretName = secretEnum[secret];

    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.responseType = 'blob';
    xhr.onload = e => {
        const res = e.target.response;
        saveAs(res, `${secretName}${fileName}`);
    };
    xhr.send();
};

// 导出文件函数
const saveAs = (obj, fileName) => {
    let ele = document.createElement('a');
    ele.download = fileName || '下载';
    ele.href = URL.createObjectURL(obj); // 绑定a标签
    ele.style.display = 'none';
    document.body.appendChild(ele); // 兼容火狐浏览器
    ele.click();
    setTimeout(function () {
        // 延时释放
        URL.revokeObjectURL(obj); // 用URL.revokeObjectURL()来释放这个object URL
        document.body.removeChild(ele); // 兼容火狐浏览器
    }, 100);
};

// 请求接口下载文件时使用些方法
export async function downloadFile(method, url, params, fileName, success) {
    let config = {
        url: process.env.VUE_APP_API_BASE_URL + url,
        method: method,
        headers: {},
        data: params,
        responseType: 'blob',
    };
    const savedToken = ls.get(STORAGE_TOKEN_KEY); // 如果 token 存在
    // 让每个请求携带自定义 token, 请根据实际情况修改

    if (savedToken) {
        config.headers['Authorization'] = 'token ' + savedToken;
    }

    const data = await axios.request(config);
    downloadBlob({
        file: data.data,
        name: fileName,
        success: success,
    });
}

const downloadBlob = options => {
    const { file, name, success, error } = options;
    try {
        const blob = new Blob([file]);
        const downloadElement = document.createElement('a');
        const href = window.URL.createObjectURL(blob); // 创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURI(name); // 下载后文件名
        downloadElement.style.display = 'none';
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href);
        if (success) {
            success();
        }
    } catch (e) {
        if (error) {
            error();
        }
    }
};

// 清除缓存，保留系统配置信息
export const clearStorage = () => {
    let platformConfig = ls.get('platformConfig');
    let locale = ls.get('locale');
    let langArr = ls.get('langArr');
    let searchHistory = ls.get('searchHistory');
    ls.clearAll();
    store.dispatch('setPlatformConfig', platformConfig);
    store.dispatch('setLang', locale);
    store.dispatch('setLangArr', langArr);
    ls.set('searchHistory', searchHistory);
}

// 密码不能少于8位且需要包含大写字母、小写字母、数字和符号中的任意三项
export const judgePassword = (password) => {
    let count = 0;
    let digital = /[0-9]/;
    let capital = /[A-Z]/;
    let lowercase = /[a-z]/;
    let spec = /[,.<>{}~!@#$%^&*_]/;
    if (digital.test(password)) {
        count++;
    }
    if (lowercase.test(password) || capital.test(password)) {
        count++;
    }
    if (lowercase.test(password) && capital.test(password)) {
        count++;
    }
    if (spec.test(password)) {
        count++;
    }
    if (count >= 3 && password.length >= 8) {
        return true;
    } else {
        return false;
    }
};

// 根据分号换行
export const handelSemicolonWrap = (str) => {
    return str.replace(/;/g, "\n");
};

// 全屏
export const fullscreen = (element) => {
    let result = null;
    if (element.requestFullscreen) {
        result = element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
        result = element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
        result = element.msRequestFullscreen();
    } else if (element.webkitRequestFullscreen) {
        result = element.webkitRequestFullScreen();
    }
    return result || Promise.reject(new Error("不支持全屏"));
};

export const cancelFullscreen = () => {
    if (document.exitFullscreen) {
        document.exitFullscreen();
    } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    }
};

export const isFullscreen = () => {
    var isFull = false;
    if (document.fullscreenEnabled || document.msFullscreenEnabled) {
        isFull = window.fullScreen || document.webkitIsFullScreen;
        if (isFull === undefined) {
            isFull = false;
        }
    }
    return isFull;
};

// 查看/下载附件
export const viewOrDownFile = (file) => {
    let filePath = file.sourceFilePath || file.filePath;
    if (viewRegex.test(filePath)) {
        window.open(filePath);
    } else {
        fileDownload(filePath, file.fileName);
    }
};

// 菜单是否存在
export const getMenuIsExist = (menuName) => {
    if (!menuName) return false;
    const info = ls.get("companyInfo");
    return info && info.menu.includes(menuName);
};

// 动态加载css
export const loadCSS = (url, isCache = false) => {
    let element = document.createElement("link");
    element.setAttribute("rel", "stylesheet");
    element.setAttribute("type", "text/css");
    if (isCache) {
        element.setAttribute("href", url + "?t=" + new Date().getTime());
    } else {
        element.setAttribute("href", url);
    }
    document.head.appendChild(element);
}

// 动态加载js
export const loadJs = (src) => {
    if (!src.trim()) return;
    return new Promise((resolve, reject) => {
        let script = document.createElement("script");
        script.type = "text/javascript";
        script.src = src;
        document.body.appendChild(script);

        script.onload = () => {
            resolve();
        };
        script.onerror = () => {
            reject();
        };
    });
};

// 获取url参数
export const getQueryVariable = (variable) => {
    const query = window.location.search.substring(1);
    let vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
};

// 数字转成汉字
export const toChinesNum = (num) => {
    let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
    let unit = ['', '十', '百', '千', '万']
    num = parseInt(num)
    let getWan = (temp) => {
        let strArr = temp.toString().split('').reverse()
        let newNum = ''
        let newArr = []
        strArr.forEach((item, index) => {
            newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index])
        })
        let numArr = []
        newArr.forEach((m, n) => {
            if (m !== '零') numArr.push(n)
        })
        if (newArr.length > 1) {
            newArr.forEach((m, n) => {
                if (newArr[newArr.length - 1] === '零') {
                    if (n <= numArr[numArr.length - 1]) {
                        newNum += m
                    }
                } else {
                    newNum += m
                }
            })
        } else {
            newNum = newArr[0]
        }

        return newNum
    }
    let overWan = Math.floor(num / 10000)
    let noWan = num % 10000
    if (noWan.toString().length < 4) {
        noWan = '0' + noWan
    }
    return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
}

// 多语言
export const setLang = (newLang) => {
    if (!newLang) return;
    /**
   * 多语言
   * 1.第一次进去 默认显示语言配置选项第一项
   * 2.进入系统切换了语言 显示切换后的语言
   * 3.语言配置选项发生变更 
   *   1.本地缓存locale在配置项中存在继续使用改语言，
   *   2.不存在,就使用配置项第一项
   */
    const oldLang = ls.get('langArr');
    const langArr = newLang.replace(/\_/g, '-').split(",");
    if (oldLang) {
        const isChange = oldLang !== newLang;
        const locale = ls.get("locale");
        if (isChange && newLang) {
            if (!langArr.includes(locale)) {
                i18n.locale = langArr[0];
                store.dispatch("setLang", langArr[0]);
                location.reload();
            }
        } else if (!langArr.includes(locale)) {
            // 本地缓存在配置中不存在
            i18n.locale = langArr[0];
            store.dispatch("setLang", langArr[0]);
        }
    } else if (langArr) {
        i18n.locale = langArr[0];
        // 为使第一次进入 没有登录 后台配置项改变跟着改变 在登录成功后设置setLang
        //  store.dispatch("setLang", langArr[0]);
    }
    newLang && store.dispatch("setLangArr", newLang);
}

export const parseDom = (html, domClassName) => {
    let ele = document.createElement('div')
    ele.innerHTML = html
    ele.className = domClassName
    return ele
}

// 当前所属平台 根据浏览器判断
export const getCurrentPlatform = () => {
    // 企业微信
    if (/wxwork/i.test(ua)) {
        return 25;
    }
    // 钉钉
    if (/dingtalk/i.test(ua)) {
        return 30;
    }
    // 飞书
    if (/lark/i.test(ua)) {
        return 40;
    }
    return 0;
};

// 等待
export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

export const joinHttps = (src) => {
  let protocol = location.protocol;
  let flag = src.includes('http') || src.includes('https');
  return flag ? src : protocol + src;
};