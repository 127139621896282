import request from '@/utils/request';
import { uploadUrl } from "@/utils/tools";

// 登录
export const login = params => request.post('study/accountLogin', params)

// 首页配置
export const homeConfig = () => request.get('study/home/detail')

// 检测登录错误次数
export const accountCheck = params => request.post('study/accountCheck', params)

// 飞书H5商店登录
export const feishuPCLogin = params => request.post('study/feishuPCLogin', params)
// 飞书H5自建登录
export const feishuPCBuildLogin = params => request.post('study/feishuBuildPCLogin', params)

// 钉钉登录 自建
export const dingPCLogin = params => request.post('study/dingtalkPCLogin', params)

// 钉钉登录 第三方
export const dingH5Login = params => request.post('study/dingtalkH5Login', params)

// 企业微信PC登录
export const wxworkPCLogin = params => request.post('study/wxworkPCLogin', params)

// 企业微信PC config
export const wxworkConfig = params => request.post('lms/customer/wxwork/config', params)

// 企业微信H5登录
export const wxworkH5Login = params => request.post('study/wxworkH5Login', params)

// 微信PC登录
export const weixinPCLogin = params => request.post(`study/weixinPCLogin`, params)

// 微信h5登录
export const weixinH5Login = params => request.post(`study/weixinH5Login`, params)

// 登出
export const logout = params => request.get('study/me/logout', params)

// 修改密码
export const pwdEdit = params => request.post('study/me/editpass', params)

// 按名称搜索企业
export const searchEnterprise = params => request.post('study/enterprise/search', params)

// 发送手机验证码
export const sendCode = params => request.post('study/mobile/sendcode', params)

// 个人注册
export const registerIndividual = params => request.post('study/register/individual', params)

// 单位注册
export const registerEnterprise = params => request.post('study/register/enterprise', params)

// 用户信息
export const userInfo = params => request.get('study/me/profile', params)

// 用户信息 完善
export const userCompleteInformation = params => request.get('study/me/IsInformation', params)

// 用户信息 完善提交
export const userCompleteInformationSubmit = params => request.post('study/me/EditIsInformation', params)

// 用户信息 完善提交审批流
export const userCompleteInformationAuditSubmit = params => request.post('study/me/AuditReceiptSubmit', params)

// 用户信息 完善提交直接保存
export const userCompleteInformationToSave = params => request.post('study/me/EditIsInformationV2', params)

// 用户企业信息
export const companyInfo = params => request.get('study/me/customer', params)

// 修改用户信息
export const userInfoEdit = params => request.post('study/me/editprofile', params)

// 未读消息数
export const msgCount = params => request.get('study/me/message/count', params)

// 消息列表
export const msgList = params => request.post('study/me/message/list', params)

// 读消息
export const msgRead = params => request.post('study/me/message/batchread', params)

// 读消息 全部
export const msgReadAll = () => request.get('study/me/message/readAll')

// 批量删除消息
export const batchDelMsg = params => request.post('study/me/message/batchdel', params)

// 清理全部消息
export const clearAllMsg = params => request.post('study/me/message/clearall', params)

// 未读消息数
export const getMessageCount = params => request.get('study/me/message/count', params)

// 扩展数据
export const extend = params => request.post('study/me/extend', params)

// 学习历史记录
export const getLearnHistoryList = params => request.post('study/me/learn/history', params)

// 学习权限验证
export const checkLearning = params => request.post('study/me/check/learning/permission', params)

// 收藏列表
export const getFavoriteList = params => request.post('study/me/favorite/list', params)

// 批量删除收藏
export const delsFavorite = params => request.post('study/me/favorite/batchdel', params)

// 我的知识
export const getDocList = params => request.post('study/me/document/list', params)

// 评论列表
export const getCommentList = params => request.post(`study/me/comment/list`, params)

// 批量删除评论
export const batchDelComment = params => request.post(`study/me/comment/batchdel`, params)

// 评论回复列表
export const getReplyList = params => request.post(`study/me/reply/list`, params)

// 批量删除回复
export const batchDelReply = params => request.post(`study/me/reply/batchdel`, params)

// 订单列表
export const orderList = params => request.post(`study/purchase/myorder/list`, params)

// 激活码订单列表
export const codeList = params => request.post(`study/purchase/myorder/code/list`, params)

// 订单详情
export const orderInfo = params => request.post(`study/purchase/myorder/detail`, params)

// 订单激活码
export const orderCodes = params => request.post(`study/purchase/myorder/code/detail`, params)

// 批量删除订单
export const delsOrder = params => request.post(`study/purchase/myorder/batchdel`, params)

// 上传头像
export const uploadAvatar = params => request.post(`study/me/upload/avatar`, params);

// 我创建的单位
export const enterpriseList = params => request.post(`study/me/enterprise/list`, params);

// 我的单位成员
export const enterpriseMemberList = params => request.post(`study/me/enterprise/member/list`, params);

// 我的单位成员学习情况
export const enterpriseMemberLearn = params => request.post(`study/me/enterprise/member/learn`, params);

// 我的单位成员学习详情
export const enterpriseMemberDetail = (userid, taskid) => request.post(`study/me/enterprise/member/${userid}/${taskid}/detail`);

// 获取服务器当前时间
export const getCurrentTime = params => request.get(`study/now`, params);

// 刷新Token *
export const refreshToken = params => request.post(`study/refreshToken`, params);

// 发送手机或邮箱验证码
export const sendcode = params => request.post('study/sendcode', params)

// 重置密码
export const resetpass = params => request.post('study/resetpass', params)

// 保存人脸采集数据
export const saveface = params => request.post('study/me/saveface', params)

// 企业微信应用市场电脑端安装回调
export const wxworkInstallApp = params => request.post('study/wxworkInstallApp', params)

// 获取我的证书列表
export const myCertificate = params => request.post('study/me/certificate/list', params)

// 危险品证书
export const myDangerouscert = params => request.post('study/me/dangerouscert/list', params)

// 切换单位
export const switchunit = id => request.post('study/me/switchunit', { id })

// 切换单位登录
export const switchAuth = params => request.post('study/switchAuth', params)

// 是否是讲师
export const isLecturer = params => request.get('study/me/lecturer', params)

// 我的点赞
export const getLikeList = params => request.post('study/me/like/list', params)

// 签到
export const sign = params => request.get('study/me/sign/in', params)

// 是否开启签到
export const hasSign = params => request.get('study/me/sign', params)

// 签到列表
export const signList = params => request.post('study/me/sign/in/list', params)

// 等级列表
export const levelList = () => request.get('study/me/memberlevel/list')

// 许可证 机器码
export const getLicence = () => request.get('study/licence')

// 三院token授权登录
export const htsyToken = params => request.post('study/htsy/auth', params)

// 三院dnname授权登录
export const htsyDnName = params => request.post('study/htsy/gatewayauth', params)

// 三院门户授权登录
export const htsyPortal = params => request.post('study/htsy/home/todoauth', params)

// 三院审批流授权登录
export const htsyBpm = params => request.post('study/htsy/bpm/auth', params)

/**
 * 钉钉扫码登录
 */
// 创建二维码
export const creatDingQR = () => request.get(`study/login/qrcode/new`);
// 通知已扫码
export const scanOk = (params) => request.post(`study/login/qrcode/scan`, params);
// 登录确认
export const comfirmDing = (params) => request.post(`study/login/qrcode/confirm`, params);
// 获取扫码状态
export const getScanState = (params) => request.post(`study/login/qrcode/state`, params);

// 三院 -- 创建授权码
export const createAuth = () => request.post(`study/me/createauth`, {});
// 三院 -- 通过授权码登录信息
export const getAuthByKey = (params) => request.post(`study/autoauth`, params);

// 省市区
export const getArea = () => request.get('study/area/tree')

// 上传文件
export const uploadFile = (params) => request.post(`${uploadUrl}study/upload/file`, params);

// partnersso 授权登录
export const ssoPCLogin = params => request.post(`study/ssoPCLogin`, params)
// partnersso 授权登录
export const ssoPCLoginV2 = params => request.post(`study/ssoPCLoginV2`, params)

// OIDCGetAuthUrl *
export const OIDCGetAuthUrl = params => request.post(`study/OIDCGetAuthUrl`, params);
// OIDC 登录 *
export const OIDCCallBack = params => request.post('study/OIDCCallBack', params)

// 个人中心 - 菜单配置
export const getMenuConfig = () => request.get('study/home/menu/pc');

// 下载中心
export const allDown = params => request.post('study/file/down/list', params)

// 下载中心 生成水印文件
export const toDownload = params => request.post('study/file/download', params)

// 下载中心 下载加次数
export const downAdd = params => request.post('study/file/downsadd', params)

// 青岛水务单点登录
export const getAuthUrlByqd = params => request.post('study/IAMGetAuthUrl', params);
export const loginByqd = params => request.post('study/IAMCallBack', params);
export const getLogoutUrlByqd = params => request.post('study/IAMGetLogoutUrl', params);

// 部门列表
export const getDepartmentTree = () => request.get('lms/department/tree');

// 人脸采集
export const submitOpenFace = params => request.post('study/me/upload/openface', params);
// 人脸识别 匹配
export const matchFace = params => request.post('study/me/upload/facecontrast', params);
// 人脸识别 提交
export const submitFace = params => request.post('study/me/facecontrast/submit', params);
// 人脸识别 活体检测
export const detectionFace = params => request.post('study/me/upload/faceliev', params);

// 疾控中心门户 首页配置
export const portalConfig = params => request.post('study/home/jkzx', params)

// 自定义表格字段 获取
export const getCustomTableConfig = () => request.get('lms/member/GetCustomTableConfig')

// 手机验证码登录
export const LoginByMobileCode = params => request.post('lms/mobileLogin', params)
// 人脸登录
export const LoginByFace = params => request.post('lms/openfaceLogin', params)

// 勋章列表
export const medalList = params => request.post('study/me/medal/list', params)

// 勋章佩戴
export const medalWear = params => request.post('study/me/medal/wear', params)

// 答疑分类
export const getQaTree = () => request.get('study/home/qa/folder/tree')
// 答疑问题列表
export const getQaList = params => request.post('study/home/qa/list', params)