import request from '@/utils/request';
import ls from '@/utils/local-storage';

// 获取图形验证码
export const getCaptcha = params => request.get('study/captcha', params)

// 轮播图
export const getBanner = params => request.get('study/banner/list', params)

// 系统封面
export const getCoverList = type => request.get('lms/cover/list?type=' + type)

// 排行榜
export const getRanking = params => request.post('study/rank/learnrank', params)

// 排行榜 积分
export const getIntegralRanking = params => request.post('study/rank/integral', params)

// 排行榜 4个状态
export const getAllRanking = params => request.post('study/rank/learnrank/multitime', params)

// 排行榜 积分 4个状态
export const getIntegralAllRanking = params => request.post('study/rank/integral/multitime', params)

// 企业配置
export const platformConfig = params => request.post('study/platform/config', params)

// 检查文件是否存在
export const fileCheck = params => request.post('study/me/file/check', { md5: params })

// 错误自动上报 *
export const report = params => request.post('study/error/report', params);

// 企业微信通讯录搜索
export const wxworkSearch = (keyword = '') => request.post('study/wxwork/contact/search', {
    keyword,
    corpId: ls.get('wxworkCorpid') || 0
});

// 钉钉通讯录搜索
export const dingSearch = (keyword = '') => request.post('study/dingtalk/contact/search', {
    keyword,
    corpId: ls.get('dingCorpid') || 0
});

// chatGpt 文本聊天
export const chatGptText = params => request.post('lms/openai/chat/text', params);

// chatGpt 画图
export const chatGptPicture = params => request.post('lms/openai/picture/generate', params);

// chatGpt 聊天记录
export const chatGptLog = params => request.post('lms/openai/chat/log', params);

// 更换语言
export const switchlanguage = params => request.post('study/me/switchlanguage', params);

// 全局搜索
export const searchAll = params => request.post('study/home/search', params);

// 国家
export const getCountry = () => request.get('lms/country');

// 岗位
export const getPosition = (params) => request.post('study/position/list', params);

// 产品线
export const getMajor = (params) => request.post('study/major/tree', params);

// 部门
export const getDepartment = (params) => request.post('study/department/tree', params);

// 字段列表
export const fieldConfigList = (module) => request.get(`study/me/customer/field/${module}/list`);