export const getters = {
    lang: state => state.lang,
    langArr: state => state.langArr,
    token: state => state.token,
    companyInfo: state => state.companyInfo,
    platformConfig: state => state.platformConfig,
    adminLink: state => state.adminLink,
    platform: state => state.platform,
    isInformation: state => state.isInformation,
    changePassword: state => state.changePassword,
    passwordExpire: state => state.passwordExpire,
    userInfo: state => state.userInfo,
    msgCount: state => state.msgCount,
    isTeacher: state => state.isTeacher,
    isExternal: state => state.isExternal,
    queryParams: state => state.queryParams,
};
