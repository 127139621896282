import mines from './mines';
let INDEXPAGEROUTE = '/home';
if (process.env.VUE_APP_CLIENT == 'htsy') { // 三院
    INDEXPAGEROUTE = '/index'
}
let homePage = [
  {
      path: '/',
      meta: {
          title: 'Head.Home',
          requireAuth: true,
          keepAlive: false
      },
      component: () => import('@/views' + INDEXPAGEROUTE + '.vue')
  },
];
if (process.env.VUE_APP_CLIENT === 'portal') {
    homePage = [
        {
            path: '/home',
            meta: {
                title: 'Head.Home',
                requireAuth: true,
                keepAlive: false
            },
            component: () => import('@/views' + INDEXPAGEROUTE + '.vue')
        },
    ]
}
export default [
    ...homePage,
    {
        path: '/study',
        name: 'study',
        meta: {
            title: 'mine.learning_center',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/study/index.vue')
    },
    {
        path: '/course',
        name: 'course',
        meta: {
            title: 'XB_CourseCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/course/index.vue')
    },
    {
        path: '/course/center',
        name: 'course_center',
        meta: {
            title: 'Lab_CourseDetail_CourseDetail',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/course/center.vue')
    },
    {
        path: '/course/detail',
        name: 'course_detail',
        meta: {
            title: 'Lab_CourseDetail_CourseDetail',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/course/detail.vue')
    },
    {
        path: '/project',
        name: 'project',
        meta: {
            title: 'MN_TrainingProject_MHome',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/project/index.vue')
    },
    {
        path: '/project/detail',
        name: 'project_detail',
        meta: {
            title: 'MN_TrainingProject_Detail',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/project/detail.vue')
    },
    {
        path: '/exam/detail',
        name: 'exam_detail',
        meta: {
            title: 'MN_Exam_Detail',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/exam/detail.vue')
    },
    {
        path: '/exam/practice/paper',
        name: 'exam_practice_paper',
        meta: {
            title: 'practice.Practice',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/exam/practice/paper.vue')
    },
    {
        path: '/exam/practice/result',
        name: 'exam_practice_result',
        meta: {
            title: 'practice.Practice',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/exam/practice/result.vue')
    },
    {
        path: '/mine',
        name: 'mine',
        redirect: '/mine/layout',
        children: mines,
        component: () => import('@/views/mine/index.vue')
    },
    {
        path: '/mine/ranking',
        name: 'mine_ranking',
        meta: {
            title: 'XB_PersonalCenter',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/ranking.vue')
    },
    {
        path: '/mine/msg',
        name: 'mine_msg',
        meta: {
            title: 'XB_Message',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/msg.vue')
    },
    {
        path: '/exam/result',
        name: 'exam_result',
        meta: {
            title: 'exam.exam_result',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/exam/result.vue')
    },
    {
        path: '/exam/review/edit',
        name: 'exam_review_edit',
        meta: {
            title: 'mine.exam_review',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/exam/review/edit.vue')
    },
    {
        path: '/exam/review/end',
        name: 'exam_review_end',
        meta: {
            title: 'mine.review_completed',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/exam/review/end.vue')
    },
    {
        path: '/package',
        name: 'package',
        meta: {
            title: 'cm_package',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/package/index.vue')
    },
    {
        path: '/package/introduce',
        name: 'package_introduce',
        meta: {
            title: 'training_package_details',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/package/introduce.vue')
    },
    {
        path: '/package/company',
        name: 'package_company',
        meta: {
            title: 'training_package_purchase',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/package/company.vue')
    },
    {
        path: '/package/pay',
        name: 'package_pay',
        meta: {
            title: 'training_package_purchase',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/package/pay.vue')
    },
    {
        path: '/package/detail',
        name: 'package_detail',
        meta: {
            title: 'training_package_details',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/package/detail.vue')
    },
    {
        path: '/mine/orderInfo',
        name: 'mine_orderInfo',
        meta: {
            title: 'training_package_details',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/orderInfo.vue')
    },
    {
        path: '/knowledge',
        name: 'knowledge',
        meta: {
            title: 'XB_KnowledgeBase',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/knowledge/index.vue')
    },
    {
        path: '/newstaff',
        name: 'newstaff',
        meta: {
            title: 'cm_newstaff',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/newstaff/index.vue')
    },
    {
        path: '/newstaff/detail',
        name: 'newstaff_detail',
        meta: {
            title: 'newstaff_details',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/newstaff/detail.vue')
    },
    {
        path: '/enterprise',
        name: 'enterprise',
        meta: {
            title: 'mine.unit_management',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/enterprise/index.vue')
    },
    {
        path: '/enterprise/study',
        name: 'enterprise_study',
        meta: {
            title: 'learning_details',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/enterprise/study.vue')
    },
    {
        path: '/news',
        name: 'news_index',
        meta: {
            title: 'news_list',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/news/index.vue')
    },
    {
        path: '/news/detail',
        name: 'news_detail',
        meta: {
            title: 'news_details',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/news/detail.vue')
    },
    {
        path: '/lecturer',
        name: 'lecturer',
        meta: {
            title: 'lecturer_center',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/lecturer/index.vue')
    },
    {
        path: '/lecturer/detail',
        name: 'lecturer_detail',
        meta: {
            title: 'XB_Lecturer_Details',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/lecturer/detail.vue')
    },
    {
        path: '/train/detail',
        name: 'train_detail',
        meta: {
            title: 'face_to_face_details',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/train/detail.vue')
    },
    {
        path: '/train/index', // 站外消息跳转和移动端路径保持一致
        name: 'train_index',
        meta: {
            title: 'face_to_face_details',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/train/index.vue')
    },
    {
        path: '/questionnaire/detail',
        name: 'questionnaire_detail',
        meta: {
            title: 'questionnaire_details',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/questionnaire/detail.vue')
    },
    {
        path: '/questionnaire/investigation',
        name: 'investigation',
        meta: {
            title: 'questionnaire_survey',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/questionnaire/investigation.vue')
    },
    {
        path: '/subject',
        name: 'subject',
        meta: {
            title: 'cm_subject',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/subject/index.vue')
    },
    {
        path: '/subject/detail',
        name: 'subject_detail',
        meta: {
            title: 'subject.detail',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/subject/detail.vue')
    },
    {
        path: '/map',
        name: 'map_index',
        meta: {
            title: 'Pub_LP',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/map/index.vue')
    },
    {
        path: '/live/detail',
        name: 'live_detail',
        meta: {
            title: 'Sys_WebCast',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/live/detail.vue')
    },
    {
        path: '/signup',
        name: 'signup',
        meta: {
            title: 'cm_sign_up',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/signup/index.vue')
    },
    {
        path: '/signup/detail',
        name: 'signup_detail',
        meta: {
            title: 'sign_up_details',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/signup/detail.vue')
    },
    {
        path: '/shop',
        name: 'shop',
        meta: {
            title: 'MN_IntegralMall_MHome',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/shop/index.vue')
    },
    {
        path: '/shop/detail',
        name: 'shop_detail',
        meta: {
            title: 'shop.commodity_detail',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/shop/detail.vue')
    },
    {
        path: '/shop/order',
        name: 'shop_order',
        meta: {
            title: 'LB_Mall_ExchangeRecord',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/shop/order.vue')
    },
    {
        path: '/shop/rule',
        name: 'shop_rule',
        meta: {
            title: 'Exchange_rules',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/shop/rule.vue')
    },
    {
        path: '/bbs',
        name: 'bbs',
        meta: {
            title: 'cm_bbs',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/bbs/index.vue')
    },
    {
        path: '/bbs/group',
        name: 'bbs_group',
        meta: {
            title: 'bbs.addpost.circle',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/bbs/group.vue')
    },
    {
        path: '/bbs/groupMember',
        name: 'bbs_groupMember',
        meta: {
            title: 'circle_members',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/bbs/groupMember.vue')
    },
    {
        path: '/bbs/topic',
        name: 'bbs_topic',
        meta: {
            title: 'bbs.addpost.topic',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/bbs/topic.vue')
    },
    {
        path: '/bbs/topicList',
        name: 'bbs_topicList',
        meta: {
            title: 'bbs.addpost.topic',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/bbs/topicList.vue')
    },
    {
        path: '/bbs/detail',
        name: 'bbs_detail',
        meta: {
            title: 'Pub_Tab_Detail',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/bbs/detail.vue')
    },
    {
        path: '/bbs/info',
        name: 'bbs_info',
        meta: {
            title: 'LB_QA_Mine',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/bbs/info/index.vue')
    },
    {
        path: '/bbs/invite',
        name: 'bbs_invite',
        meta: {
            title: 'bbs.invited',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/bbs/invite.vue')
    },
    {
        path: '/contest/detail',
        name: 'contest_detail',
        meta: {
            title: 'MN_KC_MHome',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/contest/detail.vue')
    },
    // 单页版本 在线考试
    {
        path: '/oneexam',
        name: 'one_exam',
        meta: {
            title: 'online_exam',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/mine/oneExam.vue')
    },
    // 搜索
    {
        path: '/search/:keyword',
        name: 'search',
        meta: {
            title: 'XB_Search',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/search/index.vue')
    },
    {
        path: '/questionnaire/homework',
        name: 'questionnaire_homework',
        meta: {
            title: 'questionnaire_details',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/questionnaire/homework.vue')
    },
    {
        path: '/lottery/turntable',
        name: 'lottery_turntable',
        meta: {
            title: 'WheelOfFortune',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/lottery/turntable.vue')
    },
    {
        path: '/lottery/card',
        name: 'lottery_card',
        meta: {
            title: 'ScrapingCard',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/lottery/card.vue')
    },
    {
        path: '/competition',
        name: 'competition_index',
        meta: {
            title: 'MicroCourseCompetition',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/competition/index.vue')
    },
    {
        path: '/competition/detail',
        name: 'competition_detail',
        meta: {
            title: 'CompetitionDetails',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/competition/detail.vue')
    },
    {
        path: '/competition/contest',
        name: 'competition_contest',
        meta: {
            title: 'Contest',
            requireAuth: true,
            keepAlive: false
        },
        component: () => import('@/views/competition/contest.vue')
    },
]